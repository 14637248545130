import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from "react-router-dom"
import axios from 'axios'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'
import Modal from '../components/Modal'

export default function Friend({ friend, type, updateFriends, listView, loading }) {
    const [isLoading, setIsLoading] = useState(loading)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [currentFriend, setCurrentFriend] = useState(friend)

    const user = useSelector((state) => state.userReducer.user)
    const modal = useRef(null)

    const source = axios.CancelToken.source()

    // add friend
    const handleAddFriend = async() => {
        try {
            const response = await axios.post('/friends/request/', {
                friend_id: currentFriend.id,
                action: "ADD_FRIEND"
            }, {
                cancelToken: source.token
            })

            if (response?.status === 201 || response?.status === 204) {
                setCurrentFriend(prev => { return { ...prev, profile: { ...prev.profile, friendship_status: 'PENDING', followed: true } } })
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
        }
    }


    // accept friend
    const handleAcceptFriend = async() => {
        try {
            const response = await axios.post('/friends/request/', {
                friend_id: currentFriend.id,
                action: "ACCEPT"
            }, {
                cancelToken: source.token
            })

            if (response?.status === 204) {
                setCurrentFriend(prev => { return { ...prev, profile: { ...prev.profile, friendship_status: 'FRIEND', followed: true } } })
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
        }
    }


    // delete friend
    const handleDeleteFriend = async() => {
        try {
            const response = await axios.post('/friends/request/', {
                friend_id: currentFriend.id,
                action: "DELETE"
            }, {
                cancelToken: source.token
            })

            if (response?.status === 204) {
                setCurrentFriend(prev => { return { ...prev, profile: { ...prev.profile, friendship_status: 'DELETED', followed: false } } })
                if (updateFriends && type === 'pending') {
                    updateFriends(friend, 'delete')
                }
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
        }
    }

    // follow
    const handleFollow = async() => {
        try {
            const response = await axios.post('/friends/request/', {
                friend_id: currentFriend.id,
                action: "FOLLOW"
            }, {
                cancelToken: source.token
            })

            if (response?.status === 201 || response?.status === 204) {
                setCurrentFriend(prev => { return { ...prev, profile: { ...prev.profile, followed: true } } })
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
        }
    }

    // unfollow
    const handleUnfollow = async() => {
        try {
            const response = await axios.post('/friends/request/', {
                friend_id: currentFriend.id,
                action: "UNFOLLOW"
            }, {
                cancelToken: source.token
            })

            if (response?.status === 204) {
                setCurrentFriend(prev => { return { ...prev, profile: { ...prev.profile, followed: false } } })

                // if (updateFriends) {
                //     updateFriends(friend, 'delete')
                // }
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message  || 'An unknown error occurred.')
            setError('')
        }
    }, [error])


    useEffect(() => {
        return () => source.cancel()
    }, [])


    return (
        <>
            {!listView && <>
                {!['FRIEND', 'PENDING'].includes(currentFriend.profile.friendship_status) && currentFriend.id !== user.id &&
                    <span onClick={handleAddFriend} className="d-none d-lg-block pointer bg-primary p-3 mx-1 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-500 ls-3"> Add Friend </span>
                }

                {currentFriend.profile.friendship_status === 'PENDING' &&
                    <>
                        {type === 'pending' ? <span onClick={handleAcceptFriend} className="d-none d-lg-block pointer bg-primary p-3 mx-1 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-500 ls-3"> Accept Friend </span> : <span className="d-none d-lg-block p-3 mx-1 rounded-3 text-color-lighter font-xsssss text-uppercase fw-500 "> Pending </span>}
                    </>
                }

                {!currentFriend.profile.followed && currentFriend.id !== user.id &&

                    <span onClick={handleFollow} className="d-none d-lg-block pointer p-3 mx-1 z-index-1 rounded-3 bg-grey text-primary font-xsssss text-uppercase fw-500 ls-3"> Follow </span>

                }

                {currentFriend.profile.followed && currentFriend.id !== user.id &&
                    <span onClick={handleUnfollow} className="d-none d-lg-block pointer p-3 mx-1 z-index-1 rounded-3 bg-grey text-primary font-xsssss text-uppercase fw-500 ls-3"> Unfollow </span>
                }

                {/* check if the user is a friend */}
                {['FRIEND', 'PENDING'].includes(currentFriend.profile.friendship_status) && currentFriend.id !== user.id &&
                    <>
                        {/* confirmation modal */}
                        <span onClick={() => { setError(''); modal.current.open() }} className="d-none d-lg-block pointer bg-secondary p-3 mx-1 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-500 ls-3">Delete</span>
                        <Modal
                            title={`Are you sure you want to delete ${currentFriend.profile.full_name} from your friend list?`}
                            actionBtns={[
                                { text: "Cancel", classes: "btn text-color font-xssss p-2 px-3 me-2", closeOnClick: true, onClick: null },
                                { text: "Yes", classes: "btn btn-primary font-xssss text-white p-2 px-3 me-2", onClick: handleDeleteFriend }
                            ]}
                            ref={modal}>
                        </Modal>
                    </>
                }
            </>
            }


            {/* view friend if it's list view */}
            {listView &&
                <>
                    {isLoading ? <Loader height={82}>
                        <circle cx="40" cy="32" r="32" />
                        <rect x="80" y="14" rx="4" ry="4" width="110" height="15" />
                        <rect x="80" y="39" rx="3" ry="3" width="80" height="10" />
                        {/* <rect x="70%" y="14" rx="18" ry="18" width="80" height="35" />
                        <rect x="80%" y="14" rx="18" ry="18" width="80" height="35" /> */}
                    </Loader> :


                        <div className="my-2">

                            <div className="d-flex px-2">
                                {/* image */}
                                <img src={currentFriend.profile.thumbnail} alt="user" className="me-2" style={{
                                    height: '65px',
                                    width: '65px',
                                    borderRadius: '50%'
                                }} />

                                {/* user info */}
                                <div className='ms-2 py-1'>
                                    <h5><NavLink to={`/${currentFriend.username ? currentFriend.username : currentFriend.id}`} className="font-xsss text-color fw-500 hover-underline">{currentFriend.profile.full_name}</NavLink></h5>
                                    {currentFriend.username && <h6 className="text-color-light fw-400 font-xssss">@{currentFriend.username}</h6>}
                                </div>

                                {/* buttons */}
                                <div className='ms-auto p-2 py-3'>

                                    {!['FRIEND', 'PENDING'].includes(currentFriend.profile.friendship_status) && currentFriend.id !== user.id &&
                                        <span onClick={handleAddFriend} className="pointer bg-primary p-2 px-3 d-inline-block rounded-xl font-xssss fw-500 text-white me-2"> Add Friend </span>
                                    }

                                    {currentFriend.profile.friendship_status === 'PENDING' &&
                                        <>{type === 'pending' ? <span onClick={handleAcceptFriend} className="pointer bg-primary p-2 px-3 d-inline-block rounded-xl font-xssss fw-500 text-white me-2"> Accept Friend </span> : <span className="pointer p-2 px-3 d-inline-block rounded-xl font-xssss fw-500 text-color-lighter me-2"> Pending </span>}</>
                                    }


                                    {!currentFriend.profile.followed && currentFriend.id !== user.id &&
                                        <span onClick={handleFollow} className="pointer bg-grey text-primary p-2 px-3 d-inline-block rounded-xl font-xssss fw-500 me-2"> Follow </span>
                                    }

                                    {currentFriend.profile.followed && currentFriend.id !== user.id &&
                                        <span onClick={handleUnfollow} className="pointer bg-grey text-primary p-2 px-3 d-inline-block rounded-xl font-xssss fw-500 me-2"> Unfollow </span>
                                    }

                                    {/* check if the user is a friend */}
                                    {['FRIEND', 'PENDING'].includes(currentFriend.profile.friendship_status) && currentFriend.id !== user.id &&
                                        <>
                                            {/* confirmation modal */}
                                            <span onClick={() => { setError(''); modal.current.open() }} className="pointer bg-secondary p-2 px-3 d-inline-block rounded-xl font-xssss fw-500 text-white me-2">Delete</span>
                                            <Modal
                                                title={`Are you sure you want to delete ${currentFriend.profile.full_name} from your friend list?`}
                                                actionBtns={[
                                                    { text: "Cancel", classes: "btn text-color font-xssss p-2 px-3 me-2", closeOnClick: true, onClick: null },
                                                    { text: "Yes", classes: "btn btn-primary font-xssss text-white p-2 px-3 me-2", onClick: handleDeleteFriend }
                                                ]}
                                                ref={modal}>
                                            </Modal>
                                        </>
                                    }

                                </div>

                            </div>


                        </div>
                    }

                </>}

        </>
    )
}