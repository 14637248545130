import { useEffect } from 'react'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import { Link } from "react-router-dom"
import { toast } from 'react-toastify'
import { stringifyUrlParams } from '../utils/utils'
import Friend from './Friend'
import { MdPeople } from 'react-icons/md'



export default function FriendsDirectory({ endpoint, userId, username, type, notFoundMessage, showFindFriendsBtn }) {
    const urlParams = stringifyUrlParams({ user_id: userId, username, type })
    const url = endpoint ? `${endpoint}?${urlParams}` : `/friends/list/?${urlParams}`
    const { isLoading, error, results: friends, setResults, lastItemRef: lastFriendRef } = useFetch(url)
    // const [friends, setFriends] = useState(results)


    // update friends list after delete
    const updateFriends = (friend, action) => {
        if (action === 'delete') {
            setResults(prev => { return { ...prev, results: prev.results.filter(f => f.id !== friend.id) } })
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message  || 'An unknown error occurred.')
        }
    }, [error])

    return (

        <div className="card border-0 p-3 py-2 rounded-xxl">

            {!isLoading && friends.length === 0 && <div className='text-center'>
                <h2 className='text-color-lightest'><MdPeople /></h2>
                <p className='text-color-lighter font-xsss'>
                    {notFoundMessage ? notFoundMessage : `No ${type} found.`}
                </p>
                {showFindFriendsBtn &&
                    <Link to={`/friends/find`} className="btn btn-outline-primary font-xsss m-3 p-2 px-3"> Find friends </Link>}
            </div>}

            {friends.length > 0 && friends.map((friend, index) =>
                <div ref={friends.length === index + 1 ? lastFriendRef : null} key={friend.id}>
                    <Friend friend={friend} type={type} listView={true} updateFriends={updateFriends} />
                </div>
            )}

            {isLoading && <>
                <Friend key="friend-1" loading={true} type={type} listView={true} />
                <Friend key="friend-2" loading={true} type={type} listView={true} />
            </>}
        </div>
    )

}
