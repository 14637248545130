import { useState, useEffect } from 'react'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import { useParams, NavLink } from "react-router-dom"
import { toast } from 'react-toastify'
import { stringifyUrlParams } from '../utils/utils'
import UploadFile from "../components/UploadFile"
import { MdPhotoCamera } from 'react-icons/md'

export default function ProfileImages({ user, isMyProfile }) {

    const { type } = useParams()

    const [url, setUrl] = useState(``)
    const { isLoading, error, results: media, setResults, lastItemRef: lastPostRef } = useFetch(url)

    // update url if props changes
    useEffect(() => {
        if (type === 'posts') {
            setUrl(`/newsfeed/media/?${stringifyUrlParams({ user_id: user?.id, content_type: 'images' })}`)

        } else if (type === 'profile') {
            setUrl(`/profiles/images/?${stringifyUrlParams({ user_id: user?.id, category: 'PROFILE' })}`)

        } else if (type === 'background') {
            setUrl(`/profiles/images/?${stringifyUrlParams({ user_id: user?.id, category: 'BACKGROUND' })}`)
        }
    }, [user, type])


    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message || 'An unknown error occurred.')
        }
    }, [error])

    return (

        <div className="card w-100 border-0 p-3 py-2 rounded-xxl">
            <ul className="nav nav-tabs d-flex product-info-tab border-bottom-0 ps-2 mb-2" id="pills-tab" role="tablist">
                <li className="list-inline-item me-5">
                    <NavLink
                        to={`/${user?.username ? user?.username : user?.id}/images/posts`}
                        exact
                        className="text-color fw-600 font-xssss pt-3 pb-2 ls-1 d-inline-block"
                        activeClassName="active"
                        isActive={() => type === 'posts'}>
                        Posts
                    </NavLink>
                </li>

                <li className="list-inline-item me-5">
                    <NavLink
                        to={`/${user?.username ? user?.username : user?.id}/images/profile`}
                        exact
                        className="text-color fw-600 font-xssss pt-3 pb-2 ls-1 d-inline-block"
                        activeClassName="active"
                        isActive={() => type === 'profile'}>

                        Profile
                    </NavLink>
                </li>
                <li className="list-inline-item me-5">
                    <NavLink
                        to={`/${user?.username ? user?.username : user?.id}/images/background`}
                        exact
                        className="text-color fw-600 font-xssss pt-3 pb-2 ls-1 d-inline-block"
                        activeClassName="active"
                        isActive={() => type === 'background'}>

                        Background
                    </NavLink>
                </li>

            </ul>






            {/* no media */}
            {!isLoading && media.length === 0 &&
                <div className="card w-100 text-center p-4 mb-3 m-2 border-0">
                    <div>
                        <h2 className='text-color-lightest'><MdPhotoCamera /></h2>
                        <p className='text-color-lighter font-xsss'> No images found. </p>
                    </div>
                </div>
            }


            {/* media */}
            {media.length > 0 && <div className="d-flex flex-wrap mt-2">
                {media.map((fileInfo, index) =>
                    <div ref={media.length === index + 1 ? lastPostRef : null} key={fileInfo?.id}>
                        <UploadFile fileInfo={{...fileInfo, fileSrc: fileInfo.image ? fileInfo.image : fileInfo.file}} editMode={false} key={fileInfo.name} />
                    </div>
                )}
            </div>}

            {/* loading */}
            {/* {isLoading > 0 && [...Array(2)].map((_, i) =>
                <UploadFile loading={true} key={i} />
            )} */}

        </div>


    )
}
