import { useState, useEffect } from 'react'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import { NavLink } from "react-router-dom"
import { toast } from 'react-toastify'
import { stringifyUrlParams } from '../utils/utils'
import UploadFile from "../components/UploadFile"
import { FaVideo } from 'react-icons/fa'

export default function ProfileVideos({ user, isMyProfile }) {

    const  url = `/newsfeed/media/?${stringifyUrlParams({ user_id: user?.id, content_type: 'videos' })}`
    const { isLoading, error, results: media, setResults, lastItemRef: lastPostRef } = useFetch(url)



    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message || 'An unknown error occurred.')
        }
    }, [error])

    return (

        <div className="card w-100 border-0 p-3 py-2 rounded-xxl">

            {/* no media */}
            {!isLoading && media.length === 0 &&
                <div className="card w-100 text-center p-4 mb-3 mt-4 border-0">
                    <div>
                        <h2 className='text-color-lightest'><FaVideo /></h2>
                        <p className='text-color-lighter font-xsss'> No videos found. </p>
                    </div>
                </div>
            }


            {/* media */}
            {media.length > 0 && <div className="d-flex flex-wrap mt-2">
                {media.map((fileInfo, index) =>
                    <div ref={media.length === index + 1 ? lastPostRef : null} key={fileInfo?.id}>
                        <UploadFile fileInfo={fileInfo} editMode={false} key={fileInfo.name} />
                    </div>
                )}
            </div>}

            {/* loading */}
            {/* {isLoading > 0 && [...Array(2)].map((_, i) =>
                <UploadFile loading={true} key={i} />
            )} */}

        </div>

    )
}
